import DataHandler from "./DataHandler";
import UAASAccessor from "../accessor/UAASAccessor";

export default {
    Features : {
        VOLUMETRIC_SCAN: "VolumetricScan",
        INDUCT_LABEL_PRINTING: "InductLabelPrinting",
        AISLE_FREE_PACKAGE_PICKING: "AisleFreePackagePicking",
        PICKING_MULTIPLE_BAG: "PickingMultipleBag",
        PICKING_MULTIPLE_BAG_WITH_SETTING: "PickingMultipleBagWithSetting",
        DECOUPLED_PICK_STAGE: "DecoupledPickStage",
        MANUAL_TRACKING_ID_HELP_ENABLED: "ManualTrackingIdHelpEnabled",
        STORE_DEBRIEF_ENABLED: "StoreDebriefEnabled",
        PHONE_NUMBER_SEARCH_ENABLED: "PhoneNumberSearchEnabled",
        RECEIVE_ENABLED: "ReceiveEnabled",
        RTS_HIGH_VALUE_PACKAGE_WARNING: "RTSHighValuePackageWarning",
        RTS_RESCHEDULE_VISIBILITY: "RTSRescheduleVisibility",
        RTS_FIXIT_INTEGRATION: "RTSFixitIntegration",
        RTFC_PACKAGE_DEPART: "RTFCPackageDepart",
        HIDE_REQUEST_STAGE_LIST: "HideRequestTaskList",
        ASSIGN_TO_STAGE: "AssignToStage",
        SCAN_TO_STAGE: "ScanToStage",
        MIXED_CART_FLASH_SCREEN: "MixedCartFlashScreen",
        LAST_CART_FLASH_SCREEN: "LastCartFlashScreen",
        STAGE_SERVICE_ENABLED: "StageServiceEnabled",
        PICK_ITEM_SCAN_EVENT_ENABLED:"PickItemScanEventEnabled",
        PICKING_FOR_ELIGIBLE_NODE:"PickingForEligibleNode",
        HIGH_SPR_ENABLED_AT_STATION:"HighSprEnabledAtStation",
        SCHEDULED_DELIVERY_DISPLAY_ENABLED: "ScheduledDeliveryDisplayEnabled",
        PICK_OV_BY_SORT_ZONE_ENABLED: "PickOVBySortZoneEnabled",
        AISLE_CHANGE_INDICATOR_ENABLED: "AisleChangeIndicatorEnabled",
        PARALLEL_PICK: "ParallelPick",
        SHOW_WAVE_NUMBER: "ShowWaveNumber",
        TOTAL_PICKLISTS_ENABLED: "TotalPicklistsEnabled",
        MANUAL_ASSIGNMENT_DISABLED: "ManualAssignmentDisabled",
        AUTO_ASSIGNMENT_DISABLED: "AutoAssignmentDisabled",
        AVERY_HOTSWAP_INDUCT: "AveryHotswapInduct",
        AVERY_HOTSWAP_STOW: "AveryHotswapStow",
        DRONA_ENABLED: "DronaEnabled",
        CART_HOT_SWAP: "CartHotSwapV3",
        STAGER_ATTACH_EMPTY_CART:"StagerAttachEmptyCart",
        SKIP_PRINTER_DISCONNECTION: "SkipPrinterDisconnection",
        OV_SCAN_TO_BAG_BLOCK: "OvScanToBagBlock",
        SEARCH_TRANSPORTER_ONLY_BY_BADGE_SCAN_ENABLED: "SearchTransporterOnlyByBadgeScanEnabled",
        SHOW_CART_SEQUENCE: "showCartSequence",
        ONLY_DEPART_ENABLED: "onlyDepartEnabled",
        MOBILE_PRINTER_ENABLED: "MobilePrinterEnabled",
        AR_NEXT_GEN_PRINT_SAL_AT_PICK_ENABLED: "ARNextGenPrintSALAtPickEnabled",
        AVERY_THROTTLE_ENABLED: "AveryThrottleEnabled",
        OPEN_BAG_THROTTLE_ENABLED: "OpenBagThrottleEnabled",
        CASH_DEBRIEF_ENABLED: "CashDebriefEnabled",
        CDM_FLOW_ENABLED: "CDMFlowEnabled",
        BUYBACK_TO_REGULAR_ENABLED: "BuybackToRegularEnabled",
        CASH_PAYMENT_OPTIONS_ENABLED: "CashPaymentOptionsEnabled",
        SAL_DM_CODE_SCAN: "EnableSalDmCodeScan",
        COD_RUNNING_BALANCE_DETAIL_ENABLED: "CODRunningBalanceDetailEnabled",
        PICK_DISPLAY_SAL_COLOR_INFO: "PickDisplaySalColorInfo",
        DRIVER_OVERBOOKING_ENABLED: "DriverOverbookingEnabled",
        SAM_V1_ENABLED: "IsSAMV1Enabled",
        STOW_SAL_COLOR_MISMATCH: "StowSalColorMismatchOption",
        HEAVY_BAGS_ALERTS_ENABLED: "HeavyBagsAlertsEnabled",
        LIDAR_STOW_ENABLED: "LidarStowEnabled",
        ENFORCE_CORRECT_ORDER_BAG_SCAN: "EnforceCorrectOrderBagScan",
        SPLIT_STAGING_RAMP_WORKFLOW_ENABLED: "SplitStagingRampEnabled",
        SPLIT_STAGING_AUTOLATOR_WORKFLOW_ENABLED: "SplitStagingAutolatorEnabled",
        PBL_ENABLED: "PBLEnabled",
        SAL_COLOR_AUDIT: "SalColorAudit",
        SBL_LOCAL_ENDPOINT_ENABLED: "SBLLocalEndpointEnabled",
        SHOW_SWA_SPECIFIC_PRINT_LABEL_TEXT: "ShowSWASpecificPrintLabelText",
        SWA_UNDELIVERABLE_RTO_ACTIONS_DISABLED: "SWAUndeliverableRTOActionsDisabled",
        SWA_UNDELIVERABLE_CATEGORY_DISABLED: "SWAUndeliverableCategoryDisabled",
        SWA_SINGLE_SHIPMENT_CATEGORY_BULK_SOLVING: "SWASingleShipmentCategoryBulkSolving",
        SCRUB_CONTAINER_BEFORE_ATTACH_ENABLED: "ScrubContainerBeforeAttachEnabled",
        SAL_CAPTURE_ENABLED: "SalCaptureEnabled",
        WRONG_PLANNED_STATION_RTO_DISABLED:"WrongPlannedStationRTODisabled",
        PLUG_AND_PLAY_ENABLED: "PlugAndPlayEnabled",
        HASH_ROUTER_ENABLED: "HashRouterEnabled",
        NSS_NAWS_ENDPOINTS_ENABLED: "NssNAWSEndpointsEnabled",
        SBL_DISPLAY_COLOR_ENABLED: "SBLDisplayColorEnabled",
        DRIVER_QR_CODE_SCAN_REQUIRED: "DriverQRCodeScanRequired",
        IS_RECEIVE_PACKAGE_V2_WORKFLOW_ENABLED_FOR_IN: "IsReceivePackageV2EnabledForIN",
        IS_PSEW_NNS_FLOW_ENABLED: "IsPsewNnsFlowEnabled",
        AUTO_CONNECT_TO_PREVIOUS_PRINTER: "AutoConnectToPreviousPrinter",
        PRINT_LABEL_TWICE: "PrintLabelTwice",
        PLUG_AND_PLAY_PBL_DISABLED: "PlugAndPlayPBLDisabled",
        SSD_3_ENABLED: "SSD3Enabled",
        IS_STATION_LIST_NNS_FLOW_ENABLED: "IsStationListNnsFlowEnabled",
        DAISY_CHAIN_INDUCT_FLOW_ENABLED: "IsDaisyChainInductFlowEnabled",
        IS_SCRUB_PACKAGE_NNS_ENABLED: "IsScrubPackageNnsEnabled",
        CAS_COLOR_SENSE_ENABLED: "CasColorSenseEnabled",
        DISABLE_RTO_FOR_MISSING_PS: "DisableRTOActionForMissing",
        IS_FEATURE_VALUE_NNS_ENABLED: "IsFeatureValueNnsEnabled"
    },

    isFeatureEnabled(featureName) {
        return DataHandler.userFeatures.includes(featureName);
    },

    isFeatureNotEnabled(featureName) {
       return !DataHandler.userFeatures.includes(featureName);
    },

    /**
     * Retrieves G2S2 features enabled in given stage and region
     * from UserAccountAndAttributesService
     * @param stage
     * @param region
     * @returns {Promise<void>}
     */
    async getFeatures(stage, region) {
        //If features are already retrieved skip this call.
        if (DataHandler.userFeatures.length !== 0) {
            return;
        }
        const modulesAndFeatures = await UAASAccessor.getModulesAndFeatures(stage, region);
        DataHandler.setUserFeatures(modulesAndFeatures.features.map((feature) => {
            return feature.featureName
        }))

        //populating modules training status
        if (modulesAndFeatures.modulesWithTrainingStatus) {
            DataHandler.setModulesTrainingStatus(modulesAndFeatures.modulesWithTrainingStatus);
        }

        //populating pwa module versions
        if(modulesAndFeatures.moduleVersionMap) {
            DataHandler.setModuleVersions(modulesAndFeatures.moduleVersionMap);
        }
    }
};
